import {$http} from '@/utils/https'
import {
  SET_BANNER,
  SET_PROMO,
  SET_VIDEO,
  SET_SEARCH,
  SET_CONTACTS,
  CHANGE_SEARCH_LOADING,
} from '../mutation-types';
import {
  GET_BANNER,
  GET_PROMO,
  GET_VIDEO,
  GET_SEARCH,
  GET_CONTACTS,
} from '../action-types';


const state = {
  banner: null,
  promo: null,
  video: null,
  contacts: null,
  searchLoading: false,
  search: [],
};

const getters = {
  banner: state => state.banner,
  promo: state => state.promo,
  contacts: state => state.contacts,
  search: state => state.search,
  video: state => state.video,
  searchLoading: state => state.searchLoading,
};

const actions = {
  [GET_BANNER]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/main_slider`);
      commit(SET_BANNER, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_SEARCH]: async ({commit}, payload) => {
    commit(CHANGE_SEARCH_LOADING, true);
    try {
      const response = await $http.get(`v1/search?keywords=${payload}`);
      commit(SET_SEARCH, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_SEARCH_LOADING, false);
    }
  },
  [GET_PROMO]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/info-block`);
      commit(SET_PROMO, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_VIDEO]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/video`);
      commit(SET_VIDEO, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_CONTACTS]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/contacts`);
      commit(SET_CONTACTS, response.data.data);
    } catch (e) {
      throw e;
    }
  },

};

const mutations = {
  [SET_BANNER](state, data) {
    state.banner = data;
  },
  [SET_PROMO](state, data) {
    state.promo = data;
  },
  [SET_SEARCH](state, data) {
    state.search = data;
  },
  [CHANGE_SEARCH_LOADING](state, status) {
    state.searchLoading = status;
  },
  [SET_CONTACTS](state, data) {
    state.contacts = data;
  },
  [SET_VIDEO](state, data) {
    state.video = data;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
