import {$http} from '@/utils/https'
import {
    SET_LOYALTY_PROGRAM

} from '../mutation-types';
import {
    GET_LOYALTY_PROGRAM

} from '../action-types';


const state = {
    program: ''
};

const getters = {
    loyaltyProgram: state => state.program,
};

const actions = {
    [GET_LOYALTY_PROGRAM]: async ({commit}) => {
        try {
            const response = await $http.get(`v1/loyality-programs`);

            commit(SET_LOYALTY_PROGRAM, response.data.data);
        } catch (e) {
            throw e;
        }
    },

};


const mutations = {
    [SET_LOYALTY_PROGRAM](state, data) {
        state.program = data;

    },

};
export default {
    state,
    getters,
    actions,
    mutations,
};
