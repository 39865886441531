import {mapGetters, mapMutations} from "vuex";
import {directive as onClickaway} from 'vue-clickaway';
import changeHtmlLang from "@/utils/change-html-lang";
import burgerCategory from "@/components/header/components/burger-category/index.vue";

export default {
  name: "burger-menu",
  props: {
    category: {
      type: Array,
      default: ()=> []
    }
  },
  directives: {
    onClickaway: onClickaway,
  },
  data(){
    return{
      showLangList: false,
      showCurrencyList: false,
    }
  },
  components: {
    burgerCategory
  },
  computed: {
    ...mapGetters([
      'languages',
      'currentLanguage',
    ]),
    ...mapGetters({
      locationCountry: 'setting/locationCountry',
      isAuthenticated: 'auth/isAuthenticated',
      basket: 'basket/basket',
      currencyList: 'setting/currencyList',
      currency: 'setting/currency',
      mainCategories: 'category/mainCategories'
    })
  },

  methods: {
    ...mapMutations({
      changeNewCurrency: 'setting/CHANGE_CURRENCY',
    }),
    closeLangList(){
      this.showLangList = false;
    },
    closeCurrencyList(){
      this.showCurrencyList = false;
    },
    changeLanguage(language){
      const selectedLanguage = this.languages.find(item => item.key === language.key);
      let route = this.$route.path
      if (route.slice(1, 4) === 'ru/' || route.slice(1, 4) === 'en/') {
        route = route.slice(3)
      }
      let url = ''
      selectedLanguage.translationKey === 'en' ? url = window.location.origin + route : url =  window.location.origin + `/${selectedLanguage.translationKey}` + route
      console.log(selectedLanguage, url);
      this.$setLanguage(selectedLanguage.code);
      localStorage.setItem('localization', selectedLanguage.key);
      changeHtmlLang(selectedLanguage.key);
      this.showLangList = false;
      setTimeout(() => {
        window.location.href = url;
      }, 200)
    },
    changeCurrency(item){
      this.changeNewCurrency(item)
      setTimeout(()=>{
        location.reload()
      },300)
    }
  }
}