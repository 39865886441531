import {mapActions, mapGetters, mapMutations} from "vuex";


export default {
  name: "product-card",
  props: {
    product: {
      type: Object,
      default: ()=> {}
    }
  },
  components:{
  },
  data(){
    return{
      payload: {
        product_id: null,
      }
    }
  },
  created() {
    this.payload.product_id = this.product.id
  },
  computed:{
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    })
  },

  methods:{
    ...mapActions({
      addFavorite: 'favorites/ADD_FAVORITE',
      removeFavorite: 'favorites/REMOVE_FAVORITE',
      getFavorites: 'favorites/GET_FAVORITES',
    }),
    ...mapMutations({
    }),
    changeFavorite(type){
      type ? this.removeFavorite(this.payload.product_id).then(()=>{this.product.selected = false}) : this.addFavorite(this.payload).then(()=>{this.product.selected = true})
    },
    goSign(){
      this.$router.push({name: 'sign-in'}).catch(()=>{
        console.log()
      })
    },
    deleteFavorite(){
      this.removeFavorite(this.payload.product_id).then(()=>{this.getFavorites()})
    }
  }
}