import {$http} from '@/utils/https'
import {
  SET_META,
  SET_FEEDBACK, LOADING_FEED,
} from '../mutation-types';
import {
  GET_FEEDBACK,
  GET_META,
  SEND_FEED
} from '../action-types';


const state = {
  meta: null,
  feedback: null,
  feedbackLoading: false,
};

const getters = {
  meta: state => state.meta,
  feedback: state => state.feedback,
  feedbackLoading: state => state.feedbackLoading,
};

const actions = {
  [GET_META]: async ({commit}, slug) => {
    try {
      const response = await $http.get(`v1/pages/${slug}`);
      commit(SET_META, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_FEEDBACK]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/feedback`);
      commit(SET_FEEDBACK, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [SEND_FEED]: async ({commit}, payload) => {
    try {
      commit(LOADING_FEED, true);
      const response = await $http.post(`v1/feedback`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_FEED, false);
    }
  },
};

const mutations = {
  [SET_META](state, data) {
    state.meta = data;
  },
  [SET_FEEDBACK](state, data) {
    state.feedback = data;
  },
  [LOADING_FEED](state, data) {
    state.feedbackLoading = data;
  },

};
export default {
  state,
  getters,
  actions,
  mutations,
};
