import {mapMutations} from "vuex";
import productCard from "@/components/product-card/index.vue";

export default {
  name: "main-search",
  props: {
    products: {
      type: Array,
      default: ()=> []
    },
    loading: {
      type: Boolean,
      default: ()=> false
    }
  },
  data(){
    return{
    }
  },
  components: {
    productCard
  },

  methods: {
    ...mapMutations({
    }),
  }
}