import {$http} from '@/utils/https'
import {
  SET_NEWS,
  SET_ARTICLE,
  CHANGE_PAGE_SKIP,
  RESET_SKIP,
} from '../mutation-types';
import {
  GET_NEWS,
  GET_ARTICLE,
} from '../action-types';


const state = {
  news: [],
  topNews: null,
  article: null,
  limit: 8,
  skip: 1,
  showPagination: false,
};

const getters = {
  news: state => state.news,
  topNews: state => state.topNews,
  article: state => state.article,
  skip: state => state.skip,
  limit: state => state.limit,
  showPagination: state => state.showPagination,
};

const actions = {
  [GET_NEWS]: async ({commit}, page) => {
    try {
      const response = await $http.get(`v1/news?per_page=${state.limit}&page=${page}`);
      commit(SET_NEWS, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_ARTICLE]: async ({commit}, slug) => {
    try {
      const response = await $http.get(`v1/news/${slug}`);
      commit(SET_ARTICLE, response.data.data);
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  [SET_NEWS](state, data) {
    state.topNews = data.topNew.data;
    data.items.data.forEach(el => {
      state.news.push(el);
    })
    state.showPagination = data.items.data.length >= state.limit
  },
  [SET_ARTICLE](state, data) {
    state.article = data;
  },
  [CHANGE_PAGE_SKIP](state) {
    state.limit += 8;
  },
  [RESET_SKIP](state) {
    state.limit = 8;
    state.skip = 1;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
