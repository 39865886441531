import Vue from 'vue';
import {$http} from '@/utils/https';
import {
  SET_FAVORITES,

} from '../mutation-types';

import {
  ADD_FAVORITE, GET_FAVORITES,
  REMOVE_FAVORITE,
} from '../action-types';


const state = {
favorites: null,
};

const getters = {
  favorites: state => state.favorites,
};

const actions = {
  [ADD_FAVORITE]: async ({commit}, payload) => {
    try {
      const response = await $http.post(`v1/selected`, payload);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [REMOVE_FAVORITE]: async ({commit}, id) => {
    try {
      const response = await $http.delete(`v1/selected/${id}`);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_FAVORITES]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/selected`);
      commit(SET_FAVORITES, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
};

const mutations = {
  [SET_FAVORITES](state, list) {
    state.favorites = list;
  },

};
export default {
  state,
  getters,
  actions,
  mutations,
};
