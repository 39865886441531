import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter);

const isAuthenticated = (to, from, next) => {
    if (store.state.auth.isAuthenticated) {
        next();
        return
    }
    next('/sign-in')
};

const routes = [
  {
    path: '/:lang(ru)?',
    name: 'home',
    component: () => import('../modules/home/index.vue')
  },
  {
    path: '/:lang?/news',
    name: 'news',
    component: () => import('../modules/news/index.vue')
  },
  {
    path: '/:lang?/news/:slug',
    name: 'article',
    component: () => import('../modules/article/index.vue')
  },
  {
    path: '/:lang?/about',
    name: 'about',
    component: () => import('../modules/about/index.vue')
  },
  {
    path: '/:lang?/production',
    name: 'production',
    component: () => import('../modules/production/index.vue')
  },
  {
    path: '/:lang?/personalization',
    name: 'personalization',
    component: () => import('../modules/about/index.vue')
  },
  {
    path: '/:lang?/cart',
    name: 'cart',
    component: () => import('../modules/cart/index.vue')
  },
  {
    path: '/:lang?/video',
    name: 'video',
    component: () => import('../modules/video/index.vue')
  },
  {
    path: '/:lang?/contacts',
    name: 'contacts',
    component: () => import('../modules/feedback/index.vue')
  },
  {
    path: '/:lang?/catalog/:slug',
    name: 'catalog',
    component: () => import('../modules/catalog/index.vue')
  },
  {
    path: '/:lang?/catalog',
    name: 'catalog-page',
    component: () => import('../modules/catalog-page/index.vue')
  },
  {
    path: '/:lang?/product/:slug',
    name: 'product',
    component: () => import('../modules/product/index.vue')
  },
  {
    path: '/:lang?/order',
    name: 'order',
    component: () => import('../modules/order/index.vue')
  },
  {
    path: '/:lang?/success-payment',
    name: 'success',
    component: () => import('../modules/order/success/index.vue')
  },
  {
    path: '/:lang?/error-payment',
    name: 'success',
    component: () => import('../modules/order/error/index.vue')
  },
  {
    path: '/:lang?/profile',
    redirect: '/:lang?/profile/user-info',
    name: 'profile',
    beforeEnter: isAuthenticated,
    component: () => import('../modules/profile/index.vue'),
    children: [
      {
        path: 'user-info',
        name: 'user-info',
        component: () => import('../modules/profile/sections/user-info/index.vue'),
      },
      {
        path: 'favorites',
        name: 'favorites',
        component: () => import('../modules/profile/sections/favorites/index.vue'),
      },
      {
        path: 'orders',
        name: 'orders',
        component: () => import('../modules/profile/sections/orders/index.vue'),
      },
      {
        path: 'history',
        name: 'history',
        component: () => import('../modules/profile/sections/history/index.vue'),
      },
      {
        path: 'bonus',
        name: 'bonus',
        component: () => import('../modules/profile/sections/bonus/index.vue'),
      },
    ]
  },
  {
    path: '/:lang?/sign-in',
    redirect: '/:lang?/sign-in/login',
    name: 'sign-in',
    component: () => import('../modules/sign-in/index.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('../modules/sign-in/login/index.vue'),
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('../modules/sign-in/register/index.vue'),
      },
    ]
  },
  {
    path: '/:lang?/program',
    name: 'program',
    component: () => import('../modules/program/index.vue'),
  },
  {
    path: '/:lang?/sitemap',
    name: 'sitemap',
    component: () => import('../modules/sitemap/index.vue'),
  },
  {
    path: '/:lang?/404',
    name: 'page-not',
    component: () => import('../modules/page-not-found/index.vue')
  },
  {
    path: '/:pathMatch(.*)',
    name: 'page-not-found',
    component: () => import('../modules/page-not-found/index.vue')
  },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        let el = document.querySelector('.app');
        if (to.path !== from.path) {
            if (savedPosition) {
                return savedPosition
            } else {
                el.scrollTop = 0;

                return {x: 0, y: 0}
            }
        }
    }
});

export default router
