import Vue from "vue";
import {globalLoader} from '@/helpers/variables/index';
import {$http} from '@/utils/https'
import { camelCase } from 'lodash'

import {
  FETCH_USER_DATA,
  LINK_WITH_FACEBOOK,
  LINK_WITH_GOOGLE,
  UNLINK_WITH_FACEBOOK,
  UNLINK_WITH_GOOGLE,
  UPDATE_USER_DATA,
  UPDATE_USER_PASSWORD,
  FETCH_BONUS,
  FETCH_ORDERS,
  FETCH_HISTORY,
  CREATE, UPDATE, DELETE
} from '../action-types';

import {
  SET_USER_DATA,
  LOADING_USER_DATA,
  UPDATE_USER_DATA_LOADING,
  SHOW_POPUP_UPDATE_PASSWORD,
  UPDATE_USER_PASSWORD_LOADING,

  ADD_ITEM_TO_LIST,
  CHANGE_ADDRESS_LOADING,
  REPLACE_ITEM_FROM_LIST,
  REMOVE_ITEM_FROM_LIST,
  CHANGE_EDITED_ITEM,
  CHANGE_STATUS_DIALOG_EDIT,
  CHANGE_REMOVED_ITEM,
  CHANGE_STATUS_DIALOG_REMOVE,
  SET_ORDERS,
  SET_HISTORY,
  SET_BONUS,
} from '../mutation-types';


const state = {
  user: null,
  loadingUser: false,
  updateUserLoading: false,
  showPopupUpdatePassword: false,
  updatePasswordLoading: false,
  bonus: null,
  orders: null,
  history: null,

  isLoadingAddress: false,
  editedItem: null,
  isEditDialogStatus: false,
  removedItem: null,
  isRemoveDialogStatus: false,
};

const getters = {
  updatePasswordLoading: state => state.updatePasswordLoading,
  showPopupUpdatePassword: state => state.showPopupUpdatePassword,
  user: state => state.user,
  bonus: state => state.bonus,
  orders: state => state.orders,
  history: state => state.history,
  loadingUser: state => state.loadingUser,
  updateUserLoading: state => state.updateUserLoading,

  isLoadingAddress: state => state.isLoadingAddress,
  editedItem: state => state.editedItem,
  isEditDialogStatus: state => state.isEditDialogStatus,
  removedItem: state => state.removedItem,
  isRemoveDialogStatus: state => state.isRemoveDialogStatus,
};

const actions = {
  [FETCH_USER_DATA]: async ({commit}) => {
    try {
      commit(LOADING_USER_DATA, true);

      const response = await $http.get('v1/auth/me');
      commit(SET_USER_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_USER_DATA, false);
    }
  },
  [FETCH_BONUS]: async ({commit}) => {
    try {
      const response = await $http.get('v1/bonuses');
      commit(SET_BONUS, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [FETCH_ORDERS]: async ({commit}) => {
    try {
      const response = await $http.get('v1/auth/order/history/new?include=products');
      commit(SET_ORDERS, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [FETCH_HISTORY]: async ({commit}) => {
    try {
      const response = await $http.get('v1/auth/order/history/finished?include=products');
      commit(SET_HISTORY, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [UPDATE_USER_DATA]: async ({commit}, payload) => {
    try {
      commit(UPDATE_USER_DATA_LOADING, true);
      const response = await $http.post('v1/auth/update', payload);
      commit(SET_USER_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(UPDATE_USER_DATA_LOADING, false);
    }
  },
  [UPDATE_USER_PASSWORD]: async ({commit}, payload) => {
    try {
      commit(UPDATE_USER_PASSWORD_LOADING, true);
      const response = await $http.post('v1/users/change_password', payload);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(UPDATE_USER_PASSWORD_LOADING, false);
    }
  },
  [LINK_WITH_GOOGLE]: async ({commit}, payload) => {
    globalLoader(true);
    try {
      const result = await $http.post('v1/oauth/google/link', payload);
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [LINK_WITH_FACEBOOK]: async ({commit}, payload) => {
    globalLoader(true);
    try {
      const result = await $http.post('v1/oauth/facebook/link', payload);
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [UNLINK_WITH_GOOGLE]: async ({commit}, payload) => {
    globalLoader(true);
    try {
      const result = await $http.post('v1/oauth/google/unlink');
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [UNLINK_WITH_FACEBOOK]: async ({commit}, payload) => {
    globalLoader(true);
    try {
      const result = await $http.post('v1/oauth/facebook/unlink');
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },

  [CREATE]: async ({commit}, payload) => {
    commit(CHANGE_ADDRESS_LOADING, true);
    try {
      const response = await $http.post(`v1/auth/me/address/add`, payload);
      commit(ADD_ITEM_TO_LIST, response.data.data);

      // reset dialog data
      commit(CHANGE_STATUS_DIALOG_EDIT, false)
      commit(CHANGE_EDITED_ITEM, null)
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_ADDRESS_LOADING, false);
    }
  },
  [UPDATE]: async ({commit}, payload) => {

    commit(CHANGE_ADDRESS_LOADING, true);
    try {
      const response = await $http.put(`v1/auth/me/address/update/${payload.id}`, payload);

      commit(REPLACE_ITEM_FROM_LIST, response.data.data);
      commit(CHANGE_STATUS_DIALOG_EDIT, false)
      commit(CHANGE_EDITED_ITEM, null)
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_ADDRESS_LOADING, false);
    }
  },
  [DELETE]: async ({commit}) => {
    commit(CHANGE_ADDRESS_LOADING, true);
    try {
      const response = await $http.delete(`v1/auth/me/address/delete/${state.removedItem.id}`);
      commit(REMOVE_ITEM_FROM_LIST, state.removedItem);
      commit(CHANGE_REMOVED_ITEM, null)
      commit(CHANGE_STATUS_DIALOG_REMOVE, false)
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_ADDRESS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_USER_DATA](state, data) {
    state.user = data;
  },
  [SET_BONUS](state, data) {
    state.bonus = data;
  },
  [SET_ORDERS](state, data) {
    state.orders = data;
  },
  [SET_HISTORY](state, data) {
    state.history = data;
  },
  [LOADING_USER_DATA](state, status) {
    state.loadingUser = status;
  },
  [UPDATE_USER_DATA_LOADING](state, status) {
    state.updateUserLoading = status;
  },
  [SHOW_POPUP_UPDATE_PASSWORD](state, status) {
    state.showPopupUpdatePassword = status;
  },
  [UPDATE_USER_PASSWORD_LOADING](state, status) {
    state.updatePasswordLoading = status;
  },

  [ADD_ITEM_TO_LIST](state, data) {
    state.user.addresses.data.unshift(data);
  },
  [REPLACE_ITEM_FROM_LIST](state, data) {
    const index = state.user.addresses.data.findIndex(e => e.id === data.id);
    Vue.set(state.user.addresses.data, index, data)
  },
  [REMOVE_ITEM_FROM_LIST](state, data) {
    const index = state.user.addresses.data.findIndex(e => e.id === data.id);
    Vue.delete(state.user.addresses.data, index)
  },
  [CHANGE_ADDRESS_LOADING](state, status) {
    state.isLoadingAddress = status;
  },
  [CHANGE_EDITED_ITEM](state, data) {
    state.editedItem = data;
  },
  [CHANGE_STATUS_DIALOG_EDIT](state, status) {
    state.isEditDialogStatus = status;
  },
  [CHANGE_REMOVED_ITEM](state, data) {
    state.removedItem = data;
  },
  [CHANGE_STATUS_DIALOG_REMOVE](state, status) {
    state.isRemoveDialogStatus = status;
  }
};
export default {
  state,
  getters,
  actions,
  mutations,
};
