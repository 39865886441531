import {mapGetters, mapMutations} from "vuex";
import {VueSlideToggle} from "vue-slide-toggle";

export default {
  name: "burger-sub-category",
  props: {
    category: {
      type: Object,
      default: ()=> {}
    }
  },
  data(){
    return{
      opener: false,
    }
  },
  components: {
    VueSlideToggle
  },
  computed: {
    ...mapGetters({

    })
  },

  methods: {
    ...mapMutations({

    }),
  }
}