import Vue from 'vue';
import {$http} from '@/utils/https';
import {
  CHANGE_LOADING_BASKET,
  SET_BASKET,
  CHANGE_LOADING_UPDATE_BASKET,
} from '../mutation-types';

import {
  ADD_ITEM_TO_BASKET,
  GET_BASKET,
  UPDATE_BASKET,
  REMOVE_ATTR,
} from '../action-types';
import {globalLoader} from "@/helpers/variables";
import {nanoid} from "nanoid";


const state = {
  basket: null,
  basketLoading: false,
  basketUpdateLoading: false,
};

const getters = {
  basket: state => state.basket,
  basketLoading: state => state.basketLoading,
  basketUpdateLoading: state => state.basketUpdateLoading,
};

const actions = {
  [GET_BASKET]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/order/cart?include=products`);
      commit(SET_BASKET, response.data.data);
    } catch (e) {

      // outdated cart id
      if (e.status !== 409) {
        throw e;
      }
      localStorage.setItem('cart_id', nanoid());
      try {
        const response = await $http.get(`v1/order/cart?include=products`);
        commit(SET_BASKET, response.data.data);
      }catch (e) {
        throw e;
      }

    } finally {
    }
  },
  [ADD_ITEM_TO_BASKET]: async ({commit}, {id, attribute_values, count}) => {
    commit(CHANGE_LOADING_BASKET, true)
    try {
      const response = await $http.post(`v1/order/cart/products/${id}?include=products`, {attribute_values : attribute_values, count: count});
      commit(SET_BASKET, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_BASKET, false)
    }
  },
  [UPDATE_BASKET]: async ({commit}, {product_id, id, count}) => {
    try {
      commit(CHANGE_LOADING_UPDATE_BASKET, true)
      const response = await $http.put(`v1/order/cart/products/${product_id}/order_product/${id}?include=products`, {count: count});
      commit(SET_BASKET, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_UPDATE_BASKET, false)
    }
  },
  [REMOVE_ATTR]: async ({commit}, {attr_id, id}) => {
    try {
      commit(CHANGE_LOADING_UPDATE_BASKET, true)
      const response = await $http.post(`v1/order/cart/product/${id}/remove_attribute/${attr_id}?include=products`);
      commit(SET_BASKET, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_UPDATE_BASKET, false)
    }
  },

};

const mutations = {
  [CHANGE_LOADING_BASKET](state, status) {
    state.basketLoading = status;
  },
  [CHANGE_LOADING_UPDATE_BASKET](state, status) {
    state.basketUpdateLoading = status;
  },
  [SET_BASKET](state, list) {
    state.basket = list;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
