import {mapGetters, mapMutations} from "vuex";

export default {
  name: "currency",
  data(){
    return{

    }
  },
  created() {
  },
  computed: {
    ...mapGetters({
      currency: 'setting/currency'
    })
  },
  methods: {
  }
}