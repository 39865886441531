import {$http} from '@/utils/https'
import {globalLoader, objectToQueryString} from '@/helpers/variables/index.js'
import {
  SET_PRODUCT,
  SET_BESTSELLERS,
  SET_RELATED_PRODUCTS,
  SET_PRODUCT_IMAGES,
  RESET_PRODUCT,
} from '../mutation-types';
import {
  GET_PRODUCT,
  GET_BESTSELLERS,
  GET_RELATED_PRODUCTS,
  GET_PRODUCT_IMAGES,
} from '../action-types';


const state = {
  product: null,
  productImages: null,
  bestsellers: null,
  related: null,
};

const getters = {
  product: state => state.product,
  productImages: state => state.productImages,
  bestsellers: state => state.bestsellers,
  related: state => state.related,
};

const actions = {
  [GET_PRODUCT]: async ({commit}, slug) => {
    globalLoader(true);
    try {
      const response = await $http.get(`v1/products/${slug}?include=attributes,values,categories`);
      commit(SET_PRODUCT, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [GET_RELATED_PRODUCTS]: async ({commit}, slug) => {
    try {
      const response = await $http.get(`v1/products/${slug}/related-products`);
      commit(SET_RELATED_PRODUCTS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },

  [GET_PRODUCT_IMAGES]: async ({commit}, {slug, payload}) => {
    try {
      const response = await $http.post(`v1/products/${slug}/images`, {attribute_values : payload});
      commit(SET_PRODUCT_IMAGES, response.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },

  [GET_BESTSELLERS]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/products/best-sellers`);
      commit(SET_BESTSELLERS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
};

const mutations = {
  [SET_PRODUCT](state, list) {
    state.product = list;
  },
  [SET_RELATED_PRODUCTS](state, list) {
    state.related = list;
  },
  [SET_PRODUCT_IMAGES](state, list) {
    state.productImages = list;
  },
  [SET_BESTSELLERS](state, list) {
    state.bestsellers = list;
  },
  [RESET_PRODUCT](state) {
    state.product = null;
    state.productImages = null;
  },

};
export default {
  state,
  getters,
  actions,
  mutations,
};
