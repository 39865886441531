const nonRequest = [
  'vue-i18n-manager/UPDATE_CONFIGURATION',
  'vue-i18n-manager/SET_LANGUAGE',
  'setting/GET_TRANSLATIONS',
  'setting/GET_LOCATION_DATA',
  'setting/GET_CURRENCY_LIST',
  'vue-i18n-manager/SET_TRANSLATION',
]
export default function createRequestsPlugin() {
  return store => {
    let requestsCount = 0

    store.subscribeAction({
      before: (action) => {
        if (action.type) {
        if(nonRequest.indexOf(action.type) === -1){
            requestsCount++
          }
        }

      },
      after: (action) => {
        if (action.type) {
          if (nonRequest.indexOf(action.type) === -1) {
            requestsCount--
            if (requestsCount === 0) {
              store.commit('setAllRequestsCompleted', true)
            }
          }
        }
      }
    })
  }
}