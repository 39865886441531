import Vue from 'vue'
import VueHead from 'vue-head'
Vue.use(VueHead)
export default {
  name: "breadcrumbs",
  props: {
    list: {
      type: Array,
      default: () => []
    },
    update: {
      type: Boolean,
      default: () => false,
    }
  },
  data(){
    return{

    }
  },
  watch: {
    '$route'() {
      this.setMeta()
    },
    update: {
      immediate: true,
      handler(newVal){
        if(newVal){
          this.setMeta()
        }
      }
    }
  },
  head: {
    script: function () {
      let crumbsCollection = []

      this.list.forEach((crumb, idx) => {

        let name = `${crumb.name === 'home' ? '' : '/' + crumb.name}`
        let slug = `${crumb.slug ? '/' + crumb.slug : ''}`
        let url = window.location.host + name + slug

        let obj = {
          "@type": "ListItem",
          "position": idx,
          "name": crumb.title,
          item: url
        }
        crumbsCollection.push(obj)
      })
      let obj = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": crumbsCollection
      }

      const val = JSON.stringify(obj);

      return [
        {
          id: 'bread',
          t: 'application/ld+json',
          i: val
        },
      ]
    }
  },
  created() {

  },
  computed:{

  },
  mounted() {

  },
  methods:{
    setMeta() {
      this.$emit('updateHead');
    },
  }
}