import axios from 'axios'
import {$http} from '@/utils/https'
import {
  SET_ORDER,
  SET_DELIVERY,
  SET_PAYMENT, LOADING_ORDER, LOADING_PROMOCODE

} from '../mutation-types';

import {CREATE_ORDER,
  GET_DELIVERY,
  GET_PAYMENT, SUBMIT_PROMOCODE} from '../action-types';


const state = {
  orderResult: null,
  delivery: null,
  payment: null,
  loadingOrder: false,
  loadingPromocode: false,
};

const getters = {
  orderResult: state => state.orderResult,
  delivery: state => state.delivery,
  payment: state => state.payment,
  loadingOrder: state => state.loadingOrder,
  loadingPromocode: state => state.loadingPromocode,
};

const actions = {
  [GET_DELIVERY]: async ({commit}, payload) => {
    try {
      const response = await $http.get(`v1/delivery_methods`, payload);
      commit(SET_DELIVERY, response.data.data);
      return response.data.data
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_PAYMENT]: async ({commit}, payload) => {
    try {
      const response = await $http.get(`v1/payment_methods`, payload);
      commit(SET_PAYMENT, response.data.data);
      return response.data.data
    } catch (e) {
      throw e;
    } finally {
    }
  },

  [CREATE_ORDER]: async ({commit}, payload) => {
    try {
      commit(LOADING_ORDER, true);
      const response = await $http.post(`v1/order/cart/submit?include=products`, payload);
      return response.data
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_ORDER, false);
    }
  },
  [SUBMIT_PROMOCODE]: async ({commit}, payload) => {
    try {
      commit(LOADING_PROMOCODE, true);
      const response = await $http.post(`v1/order/cart/apply-promocode`, payload);
      return response.data
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_PROMOCODE, false);
    }
  },
};

const mutations = {
  [SET_ORDER](state, data) {
    state.orderResult = data;
  },
  [LOADING_ORDER](state, status) {
    state.loadingOrder = status;
  },
  [SET_DELIVERY](state, data) {
    state.delivery = data;
  },
  [SET_PAYMENT](state, data) {
    state.payment = data;
  },
  [LOADING_PROMOCODE](state, data) {
    state.loadingPromocode = data;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
