import Vue from 'vue';
import Loader from '@/components/misc/loader.vue';
import PopupWrap from '@/components/misc/popup-wrap.vue';
import mainButton from '@/components/main-button/index.vue';
import smallLoader from '@/components/misc/small-loader.vue';
import currency from "@/components/currency/index.vue";
import breadcrumbs from "@/components/breadcrumbs/index.vue";

Vue.component('small-loader', smallLoader);
Vue.component('loader', Loader);
Vue.component('currency', currency);
Vue.component('popup-wrap', PopupWrap);
Vue.component('main-button', mainButton);
Vue.component('breadcrumbs', breadcrumbs);
Vue.component('v-pagination', window['vue-plain-pagination']);
