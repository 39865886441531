import Vue from 'vue';
import VueI18nManager from 'vue-i18n-manager';
import store from '@/store';
import router from '@/router';
import { translation } from '@/helpers/translation'


const proxy = {
  getTranslation: async () => {
    const response = await store.dispatch('setting/GET_TRANSLATIONS');
    const response2 = await store.dispatch('setting/GET_LOCATION_DATA')
    const response1 = await store.dispatch('setting/GET_CURRENCY_LIST')
    Vue.use(response, translation);
    return response;
  }
};


Vue.use(VueI18nManager, {
  store,
  router,
  proxy,
  config: {
    defaultCode: 'en-EN',
    languages: [
      {
        name: 'English',
        title: 'En.',
        key: 'en',
        code: 'en-EN',
        translationKey: 'en'
      },
      {
        name: 'Russian',
        title: 'Rus.',
        key: 'ru',
        code: 'ru-RU',
        urlPrefix: 'ru',
        translationKey: 'ru',
      },
      // {
      //   name: 'Spanish',
      //   title: 'Es.',
      //   key: 'es',
      //   code: 'es-ES',
      //   urlPrefix: 'es',
      //   translationKey: 'es',
      // },
      // {
      //   name: 'French',
      //   title: 'Fr.',
      //   key: 'fr',
      //   code: 'fr-FR',
      //   urlPrefix: 'fr',
      //   translationKey: 'fr',
      // },
      // {
      //   name: 'Hebrew',
      //   title: 'Iw.',
      //   key: 'iw',
      //   code: 'iw-IW',
      //   urlPrefix: 'iw',
      //   translationKey: 'iw',
      // },
    ],
    translations: proxy,
  },
});
