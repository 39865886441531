import {$http} from '@/utils/https'
import {globalLoader} from '@/helpers/variables/index.js'
import {
  CHANGE_PAGE_SKIP, RESET_SKIP,
  SET_CATEGORIES,
  SET_FILTERS,
  SET_PRODUCTS,
  SET_SELECTED_CATEGORY,
  SET_MAIN_CATEGORIES,
  SET_CATALOG_CATEGORIES, RESET_PRODUCTS,
  SET_MICRO,
} from '../mutation-types';
import {
  GET_CATEGORIES,
  GET_FILTERS,
  GET_PRODUCTS,
  GET_SELECTED_CATEGORY,
  GET_MAIN_CATEGORIES,
  GET_CATALOG_CATEGORIES
} from '../action-types';


const state = {
  categories: [],
  catalogCategories: [],
  selectedCategory: null,
  mainCategories: null,
  products: [],
  filters: null,
  limit: 16,
  skip: 1,
  micro: null,
  showPagination: false,
};

const getters = {
  categories: state => state.categories,
  mainCategories: state => state.mainCategories,
  selectedCategory: state => state.selectedCategory,
  filters: state => state.filters,
  products: state => state.products,
  limit: state => state.limit,
  skip: state => state.skip,
  catalogCategories: state => state.catalogCategories,
  micro: state => state.micro,
  showPagination: state => state.showPagination,
};

const actions = {
  [GET_CATEGORIES]: async ({commit}) => {
    globalLoader(true);
    try {
      const response = await $http.get(`v1/categories?include=child`);
      commit(SET_CATEGORIES, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [GET_CATALOG_CATEGORIES]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/categories`);
      commit(SET_CATALOG_CATEGORIES, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_MAIN_CATEGORIES]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/main_categories`);
      commit(SET_MAIN_CATEGORIES, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_FILTERS]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/catalog/filter`);
      commit(SET_FILTERS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_PRODUCTS]: async ({commit}, {slug, query, page}) => {
    try {
      const response = await $http.get(`v1/catalog?per_page=${state.limit}&page=${page}&categories=${slug}&${query.replace(/page=([0-9]+)/i, '')}`);
      commit(SET_PRODUCTS, response.data.data);
      commit(SET_MICRO, response.data.micro);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_SELECTED_CATEGORY]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/categories/selected`);
      commit(SET_SELECTED_CATEGORY, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
};

const mutations = {
  [SET_CATEGORIES](state, list) {
    state.categories = list;
  },
  [SET_CATALOG_CATEGORIES](state, list) {
    state.catalogCategories = list;
  },
  [SET_MAIN_CATEGORIES](state, list) {
    state.mainCategories = list;
  },
  [SET_FILTERS](state, list) {
    state.filters = list;
  },
  [SET_PRODUCTS](state, list) {
    list.forEach(el => {
      state.products.push(el)
    })
    state.showPagination = list.length >= state.limit
    // state.products = list;
  },
  [SET_MICRO](state, data) {
    state.micro = data;
  },
  [RESET_PRODUCTS](state){
    state.products = [];
  },
  [CHANGE_PAGE_SKIP](state) {
    state.limit += 16;
  },
  [RESET_SKIP](state) {
    state.limit = 16;
    state.skip = 1;
  },
  [SET_SELECTED_CATEGORY](state, list) {
    state.selectedCategory = list;
  },

};
export default {
  state,
  getters,
  actions,
  mutations,
};
