import {mapGetters, mapMutations} from "vuex";

export default {
  name: "main-footer",
  data(){
    return{

    }
  },
  created() {
  },
  computed: {
    ...mapGetters({
      contacts: 'home/contacts',
      mainCategories: 'category/mainCategories',
      categoriesList: 'category/categories',
    })
  },
  methods: {
    ...mapMutations({
      // changeLoginPopup:'popups/CHANGE_LOGIN_POPUP'
    })
  }
}