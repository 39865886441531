import {mapGetters, mapMutations} from "vuex";
import {VueSlideToggle} from "vue-slide-toggle";
import burgerSubCategory from "@/components/header/components/burger-sub-category/index.vue";

export default {
  name: "burger-category",
  props: {
    category: {
      type: Object,
      default: ()=> {}
    },
    opened: {
      type: Boolean,
      default: ()=> false,
    }
  },
  data(){
    return{
      open: false,
    }
  },
  components: {
    VueSlideToggle,
    burgerSubCategory
  },
  created() {
    this.opened ? this.open = true : this.open = false
  },
  computed: {
    ...mapGetters({

    })
  },

  methods: {
    ...mapMutations({

    }),
  }
}