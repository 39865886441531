//auth
export const GET_TOKEN = 'GET_TOKEN';
export const LOGOUT = 'LOGOUT';
export const REGISTRATION = 'REGISTRATION';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const SING_IN_WITH_GOOGLE = 'SING_IN_WITH_GOOGLE';
export const SING_IN_WITH_FACEBOOK = 'SING_IN_WITH_FACEBOOK';
//setting
export const GET_LANGUAGE_LIST = 'GET_LANGUAGE_LIST';
export const GET_LOCATION_DATA = 'GET_LOCATION_DATA';
export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';
export const GET_CATEGORIES_MENU = 'GET_CATEGORIES_MENU';
export const GET_CATEGORY_FILTERS = 'GET_CATEGORY_FILTERS';
export const GET_VARIABLES = 'GET_VARIABLES';
//header
export const GET_HEADER_SEARCH_TOOLTIP = 'GET_HEADER_SEARCH_TOOLTIP';
//search
export const GET_SEARCH_LIST = 'GET_SEARCH_LIST';
//contacts
export const SEND_FEEDBACK = 'SEND_FEEDBACK';

//faq
export const GET_FAQ_LIST = 'GET_FAQ_LIST';
export const SEND_ASK = 'SEND_ASK';
//home
export const GET_ADVANTAGE_LIST = 'GET_ADVANTAGE_LIST';
export const GET_MAIN_SLIDER = 'GET_MAIN_SLIDER';
//config
export const GET_CONFIG = 'GET_CONFIG';
//products
export const GET_SLIDER_PRODUCTS = 'GET_SLIDER_PRODUCTS';
//requisites
export const CHECK_AVAILABILITY_PROPS = 'CHECK_AVAILABILITY_PROPS';
//basket
export const CHECK_PROMO_CODE = 'CHECK_PROMO_CODE';
export const RESERVED_REQUISITES = 'RESERVED_REQUISITES';
//props selection
export const GET_PROPS_SELECTION = 'GET_PROPS_SELECTION';
export const CHECK_COUNT_PRODUCTS = 'CHECK_COUNT_PRODUCTS';
export const GET_ALL_FILTERS = 'GET_ALL_FILTERS';
//category
export const GET_LIST = 'GET_LIST';
export const GET_CUSTOM_BREADCRUMBS = 'GET_CUSTOM_BREADCRUMBS';
export const GET_LIST_CATEGORY = 'GET_LIST_CATEGORY';
//card

//personal
//setting
export const FETCH_USER_DATA = 'FETCH_USER_DATA';
//profile
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const LINK_WITH_GOOGLE = 'LINK_WITH_GOOGLE';
export const LINK_WITH_FACEBOOK = 'LINK_WITH_FACEBOOK';
export const UNLINK_WITH_GOOGLE = 'UNLINK_WITH_GOOGLE';
export const UNLINK_WITH_FACEBOOK = 'UNLINK_WITH_FACEBOOK';
//orders
export const GET_ORDERS_LIST = 'GET_ORDERS_LIST';
export const CANCEL_ORDER = 'CANCEL_ORDER';
//history
export const GET_HISTORY_LIST = 'GET_HISTORY_LIST';
//favorites
export const GET_FAVORITES_LIST = 'GET_FAVORITES_LIST';
export const ADD_PRODUCT_TO_FAVOURITES = 'ADD_PRODUCT_TO_FAVOURITES';
export const REMOVE_PRODUCT_IN_FAVORITE = 'REMOVE_PRODUCT_IN_FAVORITE';


export const GET_ITEM = 'GET_ITEM';
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const QUICK_ORDER = 'QUICK_ORDER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const CREATE_TRANSACTION = 'CREATE_TRANSACTION';

export const NOVA_POSHTA_GET_TOWNS = 'NOVA_POSHTA_GET_TOWNS';
export const NOVA_POSHTA_GET_DEPARTMENS = 'NOVA_POSHTA_GET_DEPARTMENS';

///////////
export const GET_BANNER = 'GET_BANNER';
export const GET_META = 'GET_META';
export const GET_ARTICLE = 'GET_ARTICLE';
export const GET_RELATED_PRODUCTS = 'GET_RELATED_PRODUCTS';
export const GET_BASKET = 'GET_BASKET';
export const UPDATE_BASKET = 'UPDATE_BASKET';
export const GET_NEWS = 'GET_NEWS';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_BESTSELLERS = 'GET_BESTSELLERS';
export const GET_PRODUCT_IMAGES = 'GET_PRODUCT_IMAGES';
export const GET_PROMO = 'GET_PROMO';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_SELECTED_CATEGORY = 'GET_SELECTED_CATEGORY';
export const ADD_ITEM_TO_BASKET = 'ADD_ITEM_TO_BASKET';
export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_FILTERS = 'GET_FILTERS';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const ADD_FAVORITE = 'ADD_FAVORITE';
export const REMOVE_FAVORITE = 'REMOVE_FAVORITE';
export const GET_FAVORITES = 'GET_FAVORITES';
export const GET_VIDEO = 'GET_VIDEO';
export const GET_FEEDBACK = 'GET_FEEDBACK';
export const SEND_FEED = 'SEND_FEED';
export const FETCH_BONUS = 'FETCH_BONUS';
export const GET_SEARCH = 'GET_SEARCH';
export const GET_CURRENCY_LIST = 'GET_CURRENCY_LIST';
export const GET_DELIVERY = 'GET_DELIVERY';
export const GET_PAYMENT = 'GET_PAYMENT';
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_HISTORY = 'FETCH_HISTORY';
export const GET_MAIN_CATEGORIES = 'GET_MAIN_CATEGORIES';
export const GET_CATALOG_CATEGORIES = 'GET_CATALOG_CATEGORIES';

//loyalty-program
export const GET_LOYALTY_PROGRAM = 'GET_LOYALTY_PROGRAM'
export const REMOVE_ATTR = 'REMOVE_ATTR'
export const SUBMIT_PROMOCODE = 'SUBMIT_PROMOCODE'
