import {mapMutations} from "vuex";

export default {
  name: "main-header-category-show",
  props: {
    list: {
      type: Object,
      default: ()=> {}
    }
  },
  data(){
    return{
      showCategoryList: [],
      showSubCategoryFlag: false,
    }
  },

  methods: {
    ...mapMutations({
      // changeLoginPopup:'popups/CHANGE_LOGIN_POPUP'
    }),
    showSubCategory(show, list){
      this.showSubCategoryFlag = show
      show ? this.showCategoryList = list : this.showCategoryList = []
    }
  }
}